import Vue from "vue";
import Router from "vue-router";
import jwt from "jsonwebtoken";

import Register from '@/containers/Register/Register';
import RegisterSuccess from '@/containers/Register/RegisterSuccess';
import Login from '@/containers/Login/Login';
import ChangePassword from '@/containers/ChangePassword/ChangePassword';
import User from '@/containers/User/User'

function beforeEnter(to, from, next) {
  const token = window.localStorage.getItem("token");
  if (!token) {
    Vue.prototype.$Notify({
      type: "danger",
      message:  Vue.prototype.$ct("请先登录"),
    });
    next("/login");
    return;
  }
  const tokenData = jwt.decode(token);
  console.log(tokenData);
  if (tokenData.exp < Date.now() / 1000) {
    Vue.prototype.$Notify({
      type: "danger",
      message: Vue.prototype.$ct("登录超时，请重新登录"),
    });
    window.localStorage.clear();
    next("/login");
    return;
  }
  next();
}

function beforeEnterDealer(to, from, next) {
  const token = window.localStorage.getItem("dealerToken");
  if (!token) {
    Vue.prototype.$Notify({
      type: "danger",
      message:  Vue.prototype.$ct("请先登录"),
    });
    next("./login");
    return;
  }
  const tokenData = jwt.decode(token);
  console.log(tokenData);
  if (tokenData.exp < Date.now() / 1000) {
    Vue.prototype.$Notify({
      type: "danger",
      message: Vue.prototype.$ct("登录超时，请重新登录"),
    });
    window.localStorage.clear();
    next("./login");
    return;
  }
  next();
}


Vue.use(Router);

const routes = new Router({
  mode: "history",
  routes: [
    {
      path: "/admin",
      component: () => import("@/containers/Admin/Admin"),
      children: [
        {
          path: 'login',
          component: () => import("@/containers/Admin/Login/Login")
        },
        {
          path: 'index',
          component: () => import("@/containers/Admin/Index/Index")
        },
        {
          path: 'deeperchainmap',
          component: () => import("@/containers/Admin/DeeperChainMap/DeeperChainMap")
        },
        {
          path: 'stakingCode',
          component: () => import("@/containers/Admin/StakingCode/StakingCode")
        },
        {
          path: 'staking',
          component: () => import("@/containers/Admin/Staking/Staking")
        },
        {
          path: 'sendRecord',
          component: () => import("@/containers/Admin/SendRecord/SendRecord")
        },
        {
          path: 'bridge',
          component: () => import("@/containers/Admin/Bridge/Bridge")
        },
        {
          path: 'bscbridge',
          component: () => import("@/containers/Admin/Bridge/BSCBridge")
        },
        {
          path: 'addToDeeperChain',
          component: () => import("@/containers/Admin/AddToDeeperChain/AddToDeeperChain")
        },
        {
          path: 'addToDeeperChainRecord',
          component: () => import("@/containers/Admin/AddToDeeperChain/AddToDeeperChainRecord")
        },
        {
          path: 'unStakingList',
          component: () => import("@/containers/Admin/UnStaking/UnStaking")
        },
      ],
    },
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: "/register",
      component: Register,
      name: "Register",
    },
    {
      path: "/dealer/register",
      component: () => import('@/containers/Register/DealerRegister'),
      name: "DealerRegister",
    },
    {
      path: "/dealer/login",
      component: () => import('@/containers/Login/DealerLogin'),
      name: "DealerLogin",
    },
    {
      path: "/dealer/admin",
      component: () => import('@/containers/Dealer/Admin'),
      name: "DealerAdmin",
      beforeEnterDealer
    },
    {
      path: "/dealer/stake",
      component: () => import('@/containers/Dealer/Stake'),
      name: "DealerStake",
      beforeEnterDealer
    },
    {
      path: "/dealer/stakingCode",
      component: () => import('@/containers/Dealer/StakingCode'),
      name: "DealStakingCode",
      beforeEnterDealer
    },
    {
      path: "/bindCode",
      component: () => import('@/containers/BindCode/BindCode'),
      name: "BindCode"
    },
    {
      path: "/registerSuccess",
      component: RegisterSuccess,
      name: "RegisterSuccess",
    },
    {
      path: "/login",
      component: Login,
      name: "Login",
    },
    {
      path: "/changepassword",
      component: ChangePassword,
      name: "ChangePassword",
    },
    {
      path: '/user',
      component: User,
      name: "User",
      beforeEnter 
    },

    {
      path: '/*',
      name: '404',
      redirect: '/'
    }
  ],
});

export default routes;
